document.addEventListener("turbolinks:load", function() {
  let scope = '[data-edit-asset-modal] ';
  let override_scope = '[data-override-depr-rate-modal] ';

  // Attachment file preview on hover
  $(document.body).on('mouseenter', '[data-pop-zoom]',
    function() {
      const largeImageUrl = $(this).data('pop-zoom');
      $('[data-pop-zoom-image]').attr('src', largeImageUrl);
      $('[data-pop-zoom-image-popup]').show();
    }).on('mouseleave', '[data-pop-zoom]',
    function() {
      $('[data-pop-zoom-image-popup]').hide();
    }
  );

  // Set Replacement Due date when Useful Life changes
  $(document.body).on('keyup', scope + '[data-useful-life-years]', function(e) {
    updateReplacementDueDate(scope);
  });

  // Set Replacement Due date when Purchase Date changes
  $(document.body).on('change', scope + '[data-purchase-date]', function(e) {
    updateReplacementDueDate(scope);
    warnOnFutureDate(scope);
  });

  // Intercept form submission and warn if purchase date is in the future
  let clickedSubmitButton = null;
  $(document.body).on('click', scope + '[data-asset-form] input[type=submit]', function() {
    clickedSubmitButton = this.value;
  });

  $(document.body).on('ajax:beforeSend', scope + '[data-asset-form]', function(e) {
    if (clickedSubmitButton && (clickedSubmitButton === 'Activate' || clickedSubmitButton === 'Activate and Copy')) {
      let purchaseDateEl = $(scope + '[data-purchase-date]');
      let purchaseDate = window.dateFnsParse(purchaseDateEl.val(), 'yyyy-MM-dd', new Date());
      let today = new Date();

      if (purchaseDate > today) {
        if (!window.confirm('This purchase date is in the future. Are you sure you want to continue?')) {
          e.preventDefault();
        }
      }
    }
  });

  // Also provide a warning message next to purchase date field if purchase date is in the future
  function warnOnFutureDate(scope) {
    let purchaseDateEl = $(scope + '[data-purchase-date]');
    let purchaseDate = window.dateFnsParse(purchaseDateEl.val(), 'yyyy-MM-dd', new Date());
    let today = new Date();

    let futureDateWarning = $(scope + '[data-future-date-warning]');

    if (purchaseDate > today) {
      futureDateWarning.show();
    } else {
      futureDateWarning.hide();
    }
  }

  // Calculate Replacement Date from Purchase Date + Useful Life
  function updateReplacementDueDate(scope) {
    let usefulLifeYearsEl = $(scope + '[data-useful-life-years]');
    let purchaseDateEl = $(scope + '[data-purchase-date]');
    let replacementDueDateEl = $(scope + '[data-replacement-due-date]');

    let usefulLifeYears = usefulLifeYearsEl.val();
    let purchaseDate = window.dateFnsParse(purchaseDateEl.val(), 'yyyy-MM-dd', new Date());

    if(usefulLifeYears && purchaseDate) {
      let date = window.dateFnsAddYears(purchaseDate, usefulLifeYears);
      let formattedDate = window.dateFnsFormat(date, 'yyyy-MM-dd');
      replacementDueDateEl.val(formattedDate);
    }
  }

  // Set book value, insurance value and replacement cost to purchase cost
  $(document.body).on('keyup', scope + '[data-purchase-cost]', function(e) {
    let copy_check = $(scope + '[data-copy-purchase-cost]')[0];
    if(copy_check && copy_check.checked) {
      let cost = document.getCleave(e.target);

      let book_input = $(scope + '[data-book-value]');
      book_input.val(cost.value);

      let insurance_input = $(scope + '[data-insurance-value]');
      insurance_input.val(cost.value);

      let replacement_cost_input = $(scope + '[data-replacement-cost]');
      replacement_cost_input.val(cost.value);

      document.applyCleave();
    }
  });

  // Cost To Depreciate = Purchase Cost - Residual Value
  $(document.body).on('keyup change', scope + '[data-purchase-cost]', function(e) {
    updateCtd(scope);
  });

  $(document.body).on('keyup change', scope + '[data-residual-value]', function(e) {
    updateCtd(scope);
  });

  function updateCtd(scope) {
    let pc = $(scope + '[data-purchase-cost]');
    let rv = $(scope + '[data-residual-value]');
    let ctd = $(scope + '[data-cost-to-depreciate]');

    ctd.val(document.getCleave(pc).subtract(document.getCleave(rv)));

    document.applyCleave();
  }

  // Set insurance increase to purchase cost in add-purchase modal
  $(document.body).on('change', '[data-purchase-modal] ' + '[data-add-purchase-cost]', function(e) {
    let cost = document.getCleave(e.target);

    let input = $('[data-purchase-modal] ' + '[data-insurance-increase]');
    if(document.getCleave(input).intValue === 0) {
      input.val(cost.value);
    }

    document.applyCleave('[data-purchase-modal] ');
  });

  // Scale the cost/book value change by the quantity change
  $(document.body).on('keyup change', scope + '[data-asset-quantity]', function(e) {
    let qty = parseInt($(e.target).val());
    if(Number.isNaN(qty)){ return; }

    let assetQuantity = parseInt($(scope + '[data-asset-quantity]').data('asset-quantity'));

    setScaledValue(scope, 'cost-change', assetQuantity, qty);
    $(scope + '[data-cost-change]').trigger('change');  // will applyCleave
  });

  // When Disposal Change in Purchase Cost is changed, update Accumulated Depreciation and Book Value
  $(document.body).on('keyup change', scope + '[data-cost-change]', function(e) {
    // These values are displayed as previews of the backend calculations
    // they are passed to the backend for error checking but are not actually used

    // accumulated_depreciation_change = asset.accumulated_depreciation * percent_adjustment
    // book_value_written_off = asset.book_value * percent_adjustment

    let assetCost = document.getCleave($(scope + '[data-purchase-cost]')).intValue;
    let disposalLogCostChange = document.getCleave($(scope + '[data-cost-change]')).intValue;

    let percentAdjustment = new bigDecimal(disposalLogCostChange).divide(new bigDecimal(assetCost));

    let assetAccumulatedDepreciation = document.getCleave($(scope + '[data-accumulated-depreciation]')).intValue;
    let accumulatedDepreciationChange = new bigDecimal(assetAccumulatedDepreciation).multiply(percentAdjustment).divide(new bigDecimal(100)).round(2);

    let assetBookValue = document.getCleave($(scope + '[data-book-value]')).intValue;
    let bookValueWrittenOff = new bigDecimal(assetBookValue).multiply(percentAdjustment).divide(new bigDecimal(100)).round(2);

    $(scope + '[data-accumulated-depreciation-change-cents]').val(accumulatedDepreciationChange.getValue());
    $(scope + '[data-book-value-written-off]').val(bookValueWrittenOff.getValue());

    document.applyCleave('[data-disposal-form] ');
  });

  // Get asset value from [data-dataKey], divide it by assetQuantity,
  // multiply it by qty and set the result in that control's value
  function setScaledValue(scope, dataKey, assetQuantity, qty) {
    let selector = scope + '[data-' + dataKey + ']';
    let originalValue = $(selector).data('original-value');

    let original = new bigDecimal(originalValue);
    let newValue = original.divide(new bigDecimal(assetQuantity)).multiply(new bigDecimal(qty)).round(2)
    $(selector).val(newValue.getValue());
  }

  // Show Disposal Form
  $(document.body).on('click', scope + '[data-show-disposal]', function(e) {
    $(scope + '[data-disposal-shown]').show();
    $(scope + '[data-disposal-hidden]').hide();
    $(scope + '[data-hide-disposal]')[0].scrollIntoView(false);

    // Readonly because these values must be sent during disposal
    $(scope + '[data-disposal-disable]').prop('readonly', true);
    $(scope + 'select[data-disposal-disable]').prop('disabled', true);

    // enable fields on disposal form
    $(scope + '[data-disposal-form] input').prop('disabled', false);
    $(scope + '[data-disposal-form] select').prop('disabled', false);

    // copy fields to disposal form
    $(scope + '[data-disposal-copy-to]').each(function(i, e) {
      let val = $(e).val();
      let selector = $(e).data('disposal-copy-to');
      let el = $(scope + selector);
      el.val(val);

      // set originalValue for setScaledValue()
      let rawVal = document.getCleave(e);
      el.data('original-value', rawVal.value);

      //set limits on these fields (max if numeric, else use cleave-max)
      if(e.type === 'number') {
        el.prop('max', rawVal.value);
      } else {
        el.data('cleave-max', rawVal.value);
      }
    });

    e.preventDefault();
  });

  // Hide Disposal Form
  $(document.body).on('click', scope + '[data-hide-disposal]', function(e) {
    $(scope + '[data-disposal-shown]').hide();
    $(scope + '[data-disposal-hidden]').show();

    $(scope + '[data-disposal-disable]').prop('readonly', false);
    $(scope + 'select[data-disposal-disable]').prop('disabled', false);

    // disable fields in the disposal form so their values are not submitted
    // and their validations don't prevent saving
    $(scope + '[data-disposal-form] input').prop('disabled', true);
    $(scope + '[data-disposal-form] select').prop('disabled', true);

    e.preventDefault();
  });

  // Handle changing of Category and updating related fields -
  // asking first if they have been manually changed

  $(document.body).on('change', scope + '[data-select-asset-category]', function(e) {

      // We don't want confirmation dialogs or calculations when overriding
      if($(e.target).data("override")) { return; }

      let opt = $(e.target.selectedOptions[0]);
      let percent = opt.data('opt-percent');
      let depreciationMethod = opt.data('opt-depreciation-method');
      let usefulLifeYears = opt.data('opt-useful-life-years');

      if(percent) {
        let input = $(scope + '[data-depreciation-rate-percent]');

        if (input.data('protection') === 'none') {
          setPercent(input, percent);
        } else {
          let msg = 'Click OK if you would like to change the depreciation rate to the Category rate of ' +
            percent + '%';

          if (confirm(msg)) {
            setPercent(input, percent);
          }
        }
      }

      if(depreciationMethod) {
        let input = $(scope + '[data-depreciation-method]');

        if (input.data('protection') === 'none') {
          setMethod(input, depreciationMethod);
        } else {
          let msg = 'Selecting OK will change the Depreciation Method for this Asset to "';
          if(depreciationMethod === 'sl') { msg += 'Straight Line"'; }
          if(depreciationMethod === 'dv') { msg += 'Diminishing Value"'; }
          if(depreciationMethod === 'none') { msg += 'None"'; }

          if (confirm(msg)) {
            setMethod(input, depreciationMethod);
          }
        }
      }

      if(usefulLifeYears) {
        let input = $(scope + '[data-useful-life-years]');

        if (input.data('protection') === 'none') {
          setUsefulLifeYears(input, usefulLifeYears);
        } else {
          let msg = 'Selecting OK will change the Useful Life for this Asset to ' +
            usefulLifeYears + ' years';

          if (confirm(msg)) {
            setUsefulLifeYears(input, usefulLifeYears);
          }
        }

        updateReplacementDueDate(scope);
      }
    }
  );

  // Set percentage to category percentage on the override modal

  $(document.body).on('change', override_scope + '[data-override-asset-category]', function(e) {
      let opt = $(e.target.selectedOptions[0]);
      let percent = opt.data('opt-percent');

      if(percent) {
        let input = $(override_scope + '[data-override-rate]');
        input.val(percent);
      }
    }
  );

  // Set percentage to category percentage

  function setPercent(input, percent) {
    input.val(percent);
  }

  $(document.body).on('change', scope + '[data-depreciation-rate-percent]', function(e) {
      // manual changes are protected
      if(!e.isTrigger) $(e.target).data('protection', 'protected');
    }
  );

  // Set method to category method

  function setMethod(input, method) {
    input.val(method).change();        // need to trigger change event manually for select
  }

  $(document.body).on('change', scope + '[data-depreciation-method]', function(e, f, g) {
      // manual changes are protected
      if(!e.isTrigger) $(e.target).data('protection', 'protected');

      if($(e.target).val() === 'none') {
        $(scope + '[data-useful-life-years]').val('100.0');
        $(scope + '[data-depreciation-rate-percent]').val('0.0');
      }
    }
  );

  // Set useful life to category useful life

  function setUsefulLifeYears(input, usefulLifeYears) {
    input.val(usefulLifeYears);
  }

  $(document.body).on('change', scope + '[data-useful-life-years]', function(e) {
      // manual changes are protected
    if(!e.isTrigger) $(e.target).data('protection', 'protected');
    }
  );

  // Notify server if asset modal is closed - so it can delete drafts that are not saved
  $(document.body).on("click", "[data-asset-close-notify]", function (e) {
    let assetId = $(e.target).data('asset-close-notify');
    let url = Routes.close_notify_admin_managed_asset_path(assetId);
    $.ajax({
      method: 'PUT',
      url: url
    });
  });

  // Open Asset Edit modal if requested

  let editAssetId = $('input#edit_asset').val();
  if(editAssetId) {
    let url = Routes.edit_admin_managed_asset_path(editAssetId);
    $.ajax({
      method: 'GET',
      url: url
    });
  }

  $(document.body).on('keyup', '[data-override-depr-modal] ' + '[data-purchase-cost-override]', function(e) {
    let cost = document.getCleave(e.target);

    let insurance_input = $('[data-override-depr-modal] ' + '[data-insurance-override]');
    insurance_input.val(cost.value);
    document.applyCleave();
  });

  $(document.body).on('click', '[data-asset-select]', function (e) {
    let checkBox = $(e.target);
    let assetId = checkBox.data('asset-select');

    // If shift is held down, invert and PATCH a range
    if (e.shiftKey && document.lastAssetId) {
      let a = 0;
      let b = 0;

      let checkboxes = $('[data-asset-select]');

      // Figure out which index is first on screen
      checkboxes.each(function(index, item) {
        let currentId = $(item).data('asset-select');

        if(a) {
          if (currentId === assetId  || currentId === document.lastAssetId) {
            b = currentId;
          }
        } else
        {
          if (currentId === assetId  || currentId === document.lastAssetId) {
            a = currentId;
          }
        }
      });

      // Invert between index a and b
      let invert = false;
      let setting = 0;
      let rangeToSend = {};
      checkboxes.each(function(index, item) {
        let currentId = $(item).data('asset-select');
        if (currentId === b) { invert = false; }
        if (invert) {
          if(!setting) { setting = !item.checked; }
          item.checked = setting;
          rangeToSend[currentId] = item.checked;
        }
        if (currentId === a) { invert = true; }
      });

      // Send all changed checkboxes
      let url = Routes.admin_asset_selections_path();
      $.ajax({
        method: 'POST',
        url: url,
        data: {selected: rangeToSend}
      });
    }

    // Send a single click

    let url = Routes.admin_asset_selection_path(assetId);
    let selected = checkBox[0].checked ? '1' : '0';
    $.ajax({
      method: 'PATCH',
      url: url,
      data: {selected: selected}
    });

    document.lastAssetId = assetId;
  });
});

// Check if the form is marked 'dirty' and warn
// - so user doesn't lose changes when clicking the history link
document.addEventListener("turbolinks:before-visit", function(e) {
  if ($('[data-edit-asset-modal] form').hasClass('dirty')) {
    if(!confirm('Any unsaved changes will be lost, are you sure?')) {
      e.preventDefault();
    }
  }
});

